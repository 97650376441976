/**
 * Sort default options
 */
var sort_default = {
		placeholder: 'ui-state-highlight',
        containment: 'parent',
        cursor: 'move',
        handle: '.dragger',
        tolerance: 'pointer'
    };

var sorted_table = null;

(function() {

    /**
     * Sort Opslist
     */
    var opslist_sort = {
    		stop: function (event, ui) {
    			//Confirm

				ppnotify('confirm', {
					title: 'Are you sure?',
					text: 'Are you sure you want to change the sorting?',
					type: 'info',
					showCancelButton: true,
					confirmButtontext: 'Yes',
					cancelButtonText: 'Cancel',
					closeOnConfirm: true,
					closeOnCancel: true,
					'callback': function(isConfirm){
						if(isConfirm) {

							var post = {
								'_method' : 'PATCH',
								'_token' : $('meta[name="_token"]').attr('content'),
								'data'	: []
							};

							$("#opslist").find('ul.ui-sortable li').each(function (index) {
								//Push order to post data
								var item = {
									'op_id' : $(this).data('op-id'),
									'order' : index
								};
								post['data'].push(item);
								//Renumber html
								$(this).find('.sort-order').html(index+1);
							});
							$.post(api_prefix + 'parts/' + $("#opslist ul.ui-sortable").data('op-part') + '/ops/sort', post, function(data){
								/*ppnotify('alert', {
									title: 'Success',
									message: 'Successfully updated sort',
									type: 'success'
								});*/
							});

						}else{
							$("#opslist > ul").sortable("cancel");
						}
					}
				})
    		}
    	};

    $.extend(opslist_sort,sort_default);
    $("#opslist > ul").sortable(opslist_sort);


    /***************************************************************/

    /**
     * Sort Module
     *
     * This is the sort class for the tabbed modules
     */
    var module_sort = {
    	/** Fix the table collapsing on sort (http://www.foliotek.com/devblog/make-table-rows-sortable-using-jquery-ui-sortable/) **/
    	helper: function(e,ui) {
    		ui.children().each(function() {
    			$(this).width($(this).width());
    		});
    		return ui;
    	},
		stop: function (event, ui) {
            sorted_table = ui.item.parents("table");
			//Confirm
			$("#module-sort-confirm").modal({
				keyboard: false
			});
		}
    };

    $.extend(module_sort,sort_default)
    $('table.module-sortable tbody').sortable(module_sort);

    $("body").on('click','.module-sort-save',function(e){
    	var post = {
				'_method' : 'PATCH',
				'_token' : $('meta[name="_token"]').attr('content'),
				'data'	: [],
				'context_type' : context_type,
				'context_id' : context_id
			};

		sorted_table.find('tr').each(function (index) {
			//Push order to post data
			var item = {
				'sort_id' : $(this).data('sort-id'),
				'order' : index
			};
			post['data'].push(item);

			//Renumber html
			$(this).find('.sort-order').html(index+1);
		});

        var uri = sorted_table.data('sort-uri');
        if (uri.charAt(0) != "/") {
            uri = "/" + uri;
        }
		$.post(uri, post);
		$("#module-sort-confirm").modal('hide');
    });

    $("body").on('click','.module-sort-revert',function(e){
    	sorted_table.find("tbody").sortable("cancel");
    	$("#module-sort-confirm").modal('hide');
    });

})();