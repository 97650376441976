prodproApp.controller('DocumentListController', ['$http', '$location', '$modal', '$log', '$timeout', 'alertService', 'favoriteService', function ($http, $location, $modal, $log, $timeout, alertService, favoriteService) {
    var vm = this;
    var base_url = api_prefix+ 'documents/list';
    var create_ulr = '/documents/create';
    vm.resource_url = api_prefix + 'documents';
    vm.objText = {
        singular: 'document',
        plural: 'documents'
    };

    // Mixin Module functionality into DocumentList.
    angular.extend(this, new ModuleController(vm, base_url, $http, $location, $log, $modal));

    vm.loadDataSuccess = function() {
        vm.loadImages();
    };

    vm.initDefaultObj = function() {

    };

    vm.hideFavorites = function(docType) {
        return !context_mode || context_mode !== 'default'
    };

    vm.isImage = function(docType) {
        if (context = !context_mode || context_mode !== 'default') {
            return false;
        }
        if (typeof docType === "undefined") {
            return true;
        }
        return docType.search('image') == 0;
    };

    vm.isFavorite = function(index) {
        return index === favoriteService.getIndex();
    }

    vm.setFavorite = function(id) {
        for(var i = 0; i < vm.data.data.length; i++) {
            if(vm.data.data[i].id  === id) {
                favoriteService.updateFavorite(vm.data.data[i].imageIndex).success(function (data) {
                    if (data.type !== 'success') {
                        vm.pushAlert({type: data.type, msg: data.msg});
                    }
                    console.log(favoriteService.getImage());
                }).error(function (data) {
                    $log.error.log(data);
                });
            }
        }
    };

    vm.loadImages = function() {
        var images = new Array();
        var imageIndex = 0;
        for (var i = 0; i < vm.data.data.length; i++) {
            vm.data.data[i].isImage = false;
            if (vm.isImage(vm.data.data[i].type)) {
                //dealing with an image, add it to the images
                vm.data.data[i].isImage = true;
                vm.data.data[i].imageIndex = imageIndex++;
                images.push({
                    'url': vm.data.data[i].path,
                    'thumbUrl': vm.data.data[i].thumbnail,
                    'id': vm.data.data[i].id,
                    'name': vm.data.data[i].name
                })
                if(vm.data.data[i].favorite === 1) {
                    favoriteService.setFavorite(vm.data.data[i].imageIndex);
                }
            }
        }
        favoriteService.setImages(images);
    };

    vm.openLightboxModal = function (index) {
        favoriteService.openLightboxModal(index);
    };

    vm.createDocument = function () {
        var url = '/documents/create';

        if (context_type && context_id) {
            var map = {};
            map["App\\Repos\\Parts\\Part"] = 'parts';
            map["App\\Repos\\Ops\\Ops"] = 'ops';
            map["App\\Repos\\QAs\\QA"] = 'qas';
            if (map[context_type]) {
                url += '/' + map[context_type] + '/' + context_id;
            }
        }

        window.location.href = url;

    };

    vm.editDocument = function (id) {
        var modalInstance = $modal.open({
            templateUrl: 'confirmDelete.html',
            controller: 'ModalInstanceCtrl',
            size: 'sm'
        });

        modalInstance.result.then(function () {
            // delete confirmed
            $http({
                method: 'PUT',
                url: api_prefix + 'documents/' + id
            }).success(function (data) {
                vm.pushAlert({type: data.type, msg: data.msg});
                vm.loadData();
            }).error(function (data) {
                $log.error(data);
            });
        }, function () {
            $log.info('Document Delete Modal dismissed at: ' + new Date());
        });
    };

    vm.deleteDocument = function (id) {
        var modalInstance = $modal.open({
            templateUrl: 'confirmDelete.html',
            controller: 'ModalInstanceCtrl',
            size: 'sm',
            resolve: {
            	objText: function() {
            		return 'document';
            	}
            }
        });

        modalInstance.result.then(function (form) {
            // delete confirmed
            $http({
                method: 'DELETE',
                url: api_prefix + 'documents/' + id
            }).success(function (data) {
                vm.pushAlert({type: data.type, msg: data.msg});
                alertService.add(data.type, data.msg);
                var myAlerts = alertService.getAlerts();
                [].forEach.call(myAlerts,function(alert) {
                    console.log(alert.msg);
                });
                vm.loadData();
            }).error(function (data) {
                $log.error(data);
            });
        }, function () {
            $log.info('Document Delete Modal dismissed at: ' + new Date());
        });
    };

    vm.loadData();
}]);
