(function() {
	var link;

	var loading_container = '<div class="loading-container"><i class="fa fa-circle-o-notch fa-spin fa-3x"></i></div>';

	//Enter new entry
	$("body").on('click','a.shopping-list-add',function(e){
		e.preventDefault();

		//Close all editable windows
		closePanels();

		//Add row at first spot
		$("#shopping-list-results tbody").prepend(emptyRow());

		var template = cloneTemplate();
		template = template.find('.shopping-edit');

		$('tr.edit-form td').html(template).trigger('pp:jqueryHelp');
	});

	//Edit existing entry
	$("body").on("click","#shopping-list-results a.edit",function(e) {
		e.preventDefault();

		link = $(this);

		//save current state
		var current_id = $('#shopping-list-results input.item-id').val();
		var edit_id = $("#shopping-list-results input.item-edit-id").val();

		//Close all editiable windows
		closePanels();

		//check to see if edit mode is already displaying,
		// if so diplay the read-only mode
		if (edit_id !== undefined && current_id == edit_id) {
			link.parents('tr').find('a.btn-expand').trigger('click');
			return;
		}

		//Add row under this one
		link.parents('tr').after(emptyRow());

		link.parents('tr').find('a.btn-expand i').removeClass('fa-chevron-right').addClass('fa-chevron-down');

		//Load up our data
		$.get(link.attr("href"),function(data) {
			var template = cloneTemplate();

			template = template.find('.shopping-edit');

			//Populate inputs and text area
			template.find("input,textarea").each(function(index){
				var attr = $(this).attr("name");
				$(this).val(data.data[attr]);
			});

			//Populate select
			template.find("select").each(function(index){
				var attr = $(this).attr("name");
				$(this).find('option[value="' + data.data[attr] + '"]').attr("selected",true);
			});

			//Populate chosen-drop (for multiselect)
      template.find("select#shoppingtags").each(function(index){
        var attr = $(this).attr("name");
        var tags = data.data['shopping_tags'].split(',');
        for ( var i = 0, l = tags.length, o; i < l; i++ ) {
           $(this).find('option[value="' + tags[i] + '"]').attr("selected", true);
        }
      });
			//Set ID
			template.find('input.item-edit-id').val(link.parents('tr').find('input.item-id').val());

			$('tr.edit-form td').html(template).trigger('pp:jqueryHelp');

		});
	});

	//Show entry
	$("body").on("click","#shopping-list-results a.btn-expand",function(e) {
		e.preventDefault();
		link = $(this);
		//Check row is currently open
		var opening = false;
		if (link.find('i').hasClass('fa-chevron-right')) {
			opening = true;
		}

		//Set all arrows to closed
		closePanels();

		if (opening) {
			openShow(link);
		}

	});

	//Enter reorder
	$("body").on('click','a.shopping-list-reorder',function(e){
		e.preventDefault();

		//Close all editiable windows
		closePanels();

		link = $(this);

		//Confirm
		$('#reorder-confirm').modal();
	});

	//After reorder confirm
	$("body").on('click','.btn-reorder',function(e){
		e.preventDefault();

		//Set modal to loading screen
		$("#reorder-confirm .modal-content").html('<div><h3>Reordering...</h3></div>' + loading_container);

		$.get(link.attr("href"),function(data) {

			var post_data = {};
			//Clean up the object for resaving
			post_data['_token'] = $('meta[name="_token"]').attr('content');
			post_data['due_date'] = data.data['due_date'];
			post_data['notes'] = data.data['notes'];
			post_data['part_no'] = data.data['part_no'];
			post_data['po_no'] = data.data['po_no'];
			post_data['priority_id'] = data.data['priority_id'];
			post_data['prod_type_id'] = data.data['prod_type_id'];
			post_data['product_id'] = data.data['product_id'];
			post_data['quantity'] = data.data['quantity'];
			post_data['retailer_id'] = data.data['retailer_id'];
      post_data['shopping_tags'] = data.data['shopping_tags'];

			$.post('/api/v1/shopping',post_data,function() {
				//Reload page
				location.reload(true);
			});
		});
	});

	//Cancel
	$("body").on("click","tr.edit-form a.btn-cancel",function(e){
		e.preventDefault();

		closePanels();
	});

	//Save
	$("body").on("click","tr.edit-form a.btn-save",function(e){
		e.preventDefault();

		ppnotify('confirm', {
			title: 'Confirm Save',
			text: 'Are you sure you want to save this item?',
			type: 'info',
			closeOnConfirm: true,
			closeOnCancel: true,
			callback: function (isConfirm) {
				if (isConfirm) {

					var form = $('tr.edit-form form');
					var id = form.find('input.item-edit-id').val();

					//Add token
					addToken(form);

					if (id > 0) {
						$.ajax({
							type: 'PUT',
							url: '/api/v1/shopping/' + id,
							data: form.serialize(),
						}).done(function(obj) {
							//Remove edit box
							$('tr.edit-form').remove();

							//Update prior row
							var orig_row = $("#shopping-list-results").find('input.item-id[value="' + obj.data.id + '"]').parents('tr');

							//Product
							orig_row.find('td').eq(5).html(obj.data.product);
							//Retailer
							orig_row.find('td').eq(6).html(obj.data.retailer);

							/*ppnotify('alert', {
								title: 'Success',
								message: 'Successfully updated item',
								type: 'success'
							});*/
							location.reload(true);

						}).fail(function(jqXHR) {
							showErrors(jqXHR,form);

							ppnotify('alert', {
								title: 'Error',
								message: 'Error updating item',
								type: 'error'
							});
						});
					}else {
						$.ajax({
							type: 'POST',
							url: '/api/v1/shopping/',
							data: form.serialize()
						}).done(function(obj) {
							//Reload page
							location.reload(true);

							/*ppnotify('alert', {
								title: 'Success',
								message: 'Successfully updated item',
								type: 'success'
							});*/

						}).fail(function(jqXHR) {
							showErrors(jqXHR,form);

							ppnotify('alert', {
								title: 'Error',
								message: 'Error updating item',
								type: 'error'
							});
						});
					}
 				}

			}
		});

	});

	//Order button
	$("body").on('click',"#shopping-list-results a.btn-order",function(e) {
		e.preventDefault();

		var link = $(this);

		ppnotify('confirm', {
			title: 'Confirm Accepting',
			text: 'Are you sure you want to accept this order?',
			type: 'info',
			closeOnConfirm: true,
			closeOnCancel: true,
			callback: function (isConfirm) {
				if (isConfirm) {
					submitAction('order',link);
					/*ppnotify('alert', {
						title: 'Success',
						message: 'Successfully accepted order',
						type: 'success'
					});*/
				}
			}
		});



	});

	//Revceive button
	$("body").on('click',"#shopping-list-results a.btn-receive",function(e) {
		e.preventDefault();

		//Close all editiable windows
		closePanels();

		link = $(this);

		//Confirm
		$('#received-confirm').modal();

	});

	//After receive modal
	$("body").on('click','.btn-save-receive',function(e){
		e.preventDefault();

		var form = $("#received-confirm form");
		var id= link.parents('tr').find('input.item-id').val();

		//Set modal to loading screen
		$("#receive-confirm .modal-content").html('<div><h3>...</h3></div>' + loading_container);
		$.ajax({
			type: 'PATCH',
			url: '/api/v1/shopping/' + id + '/receive',
			data: form.serialize(),
		}).done(function(obj) {
			location.reload();
		}).fail(function(jqXHR) {
			showErrors(jqXHR,form);
		});
	});

	function cloneTemplate() {
		var template = $("#shopping-item-template").clone();

		template.attr('id','shopping-item').css('display','block');

		template.find("input,textarea").each(function(index){
			$(this).removeClass('no-jquery');
		});

		template.find("select").each(function(index){
			//remove chosen blocker
			$(this).removeAttr('no-chosen').removeClass('no-jquery');
		});

		//Make sure token is removed
		template.find('input[name="_token"]').remove();

		return template;
	}

	function emptyRow() {
		return '<tr class="edit-form"><td colspan="12">' + loading_container + '</td></tr>';
	}

	function showErrors(jqXHR,form) {
		if (jqXHR.status == 422) {
			$.each(jqXHR.responseJSON, function(k,v) {
				//Loop through the errors and display them
				var field = form.find('input[name="' + k + '"],select[name="' + k + '"]').parents('.form-group');
				field.addClass('has-error');
				field.append('<span class="help-block">' + v + '</span>');
			});
		}
	}

	//Adds token to the form
	function addToken(form) {
		//Add token
	    var token =
	    	$('<input>', {
	            'type': 'hidden',
	            'name': '_token',
	              'value': $('meta[name="_token"]').attr('content')
	           });
	    form.remove('input[name="_token"]').append(token);

	    return form;
	}

	//Handles the actions for patching buttons
	function submitAction(action,link){
		var id= link.parents('tr').find('input.item-id').val();

		link.fadeOut(400,function() {
			$.ajax({
				type: 'PATCH',
				url: '/api/v1/shopping/' + id + '/' + action,
				data: {_token: $('meta[name="_token"]').attr('content')}
			}).done(function(obj){
				var cell = link.parents('td');
				cell.remove('a');

				$('<div style="display:none;">' + obj.data.date + '<br />(' + obj.data.name + ')</div>').appendTo(cell).fadeIn();

				//Set status
				var status = link.parents('tr').find('.item-status');
				if (action == "order") {
					status.switchClass('status-new','status-ordered');

					//Add receive button
					link.parents('tr').find('td').eq(10).html('<a class="btn btn-primary btn-receive" href="#">Receive</a>');
				}
			});

		});
	}

	//Opens the show form
	function openShow(link) {
		link.find('i').removeClass('fa-chevron-right').addClass('fa-chevron-down');
		//Add row
		link.parents('tr').after(emptyRow());

		//Load data
		$.get(link.attr("href"),function(data) {
			var template = cloneTemplate();

			template = template.find('.shopping-show');

			//Populate the data
			for (key in data.data) {
				var value = data.data[key];

				if (value === null || value == '') {
					value = '&nbsp;'
				}
				template.find('div[data-shopping-id="' + key + '"]').html(value);
			}

			$('tr.edit-form td').html(template);
		});
	}

	//Closes all panels and resets buttons
	function closePanels() {
		$("#shopping-list-results tr.edit-form").remove();
		$("#shopping-list-results a.btn-expand i").removeClass('fa-chevron-down').addClass('fa-chevron-right');
	}


	$('body').on('change','.shopping-edit select', function(e) {
		//Check if there are any other dropdowns referencing this one
        var parent = $(this);
        var id = parent.attr('id');

    		var kids = $(this).parents('.shopping-edit').find('select[data-related-to="' + id + '"]');
        kids.each(function() {
            kid = $(this);
            $.post('/api/v1/dd/list', {
                'model': kid.data('add-option'),
                'parent_id': parent.val(),
                '_token': $('meta[name="_token"]').attr('content')
            }, function(data) {
                //Clear options
								//console.log('loop fires.');  Apparently this loop NEVER Fires
								//TODO: Fix this crap.  Chosen fields don't reload properly until you reload the whole page.
                kid.find('option').remove().end();
                kid.append('<option value="" selected="selected"></option>');

                $.each(data,function(k,v) {
                    kid.append('<option value="' + k + '">' + v + '</option>');
                });
                kid.trigger('chosen:updated');
            });
        });
	});

})();
