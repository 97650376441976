prodproApp.factory('alertService', [function () {
    var alertService = {};
    var alerts = [];

    alertService.add = function (type, msg) {
        alerts.push({
            'type': type, 'msg': msg, 'close': function () {
                alertService.closeAlert(this);
            }
        });
    };

    alertService.closeAlert = function (alert) {
        alertService.closeAlertIdx(alerts.indexOf(alert));
    };

    alertService.closeAlertIdx = function (index) {
        alerts.splice(index, 1);
    };

    alertService.getAlerts = function () {
        return alerts;
    };

    if (typeof globalAlertType != "undefined" && typeof globalAlertMsg != "undefined") {
        alertService.add(globalAlertType, globalAlertMsg);
    }

    return alertService;
}])

    .directive('alerts', [function () {
        return {
            bindToController: true,
            controller: "alertsController as a",
            template: '<alert ng-repeat="alert in a.alertService.getAlerts()" type="{{alert.type}}" close="a.alertService.closeAlert($index)">{{alert.msg}}</alert>'
        };
    }])

    .controller("alertsController", ['alertService', function (alertService) {
        var vm = this;
        vm.alertService = alertService;
    }]);


