prodproApp.controller('ToolController', ['$scope', '$http', '$location', '$modal', '$log', '$timeout', 'alertService', function ($scope, $http, $location, $modal, $log, $timeout, alertService) {
    var vm = this;
    var base_url = api_prefix + 'tools/list';
    vm.sort_url = api_prefix + 'tools/sort';
    vm.resource_url = api_prefix + 'tools';
    vm.objText = {
        singular: 'tool',
        plural: 'tools'
    };
    vm.file_model = 'App\\Repos\\Tools\\Tool';

    // Mixin Module functionality.
    angular.extend(this, new ModuleController(vm, base_url, $http, $location, $log, $modal));
    angular.extend(this, new SortController(vm, $http, $modal, $timeout));

    vm.acceptedFiles = 'image/jpg,image/jpeg,image/png,image/gif';
    angular.extend(this, new FileController(vm, $scope, $http, $modal, $timeout));

    vm.initDefaultObj = function() {
        vm.defaultObj.name = '[New Tool]';
        vm.defaultObj.collet_id = "";
        vm.defaultObj.cutting_tool_id = "";
        vm.defaultObj.cutting_type_id = "";
        vm.defaultObj.edi =  "";
        vm.defaultObj.holder_id = "";
        vm.defaultObj.insert_grade_id = "";
        vm.defaultObj.insert_id = "";
        vm.defaultObj.location_id = "";
        vm.defaultObj.manufacturer_id = "";
        vm.defaultObj.material_id = "";
        vm.defaultObj.notes = "";
        vm.defaultObj.offset_1 = "";
        vm.defaultObj.offset_2 = "";
        vm.defaultObj.pocket_id = "";
        vm.defaultObj.sleeve_id = "";
        vm.defaultObj.station_id = "";
        vm.defaultObj.stickout_length = "";
    };

    vm.loadDataSuccess = function() {
        for (var i = 0; i < vm.data.data.length; i++) {
            vm.onCuttingToolTypeChange(i);
        }

        setTimeout(function(){
            jQuery('textarea').autogrow();
        }, 10);
    };

    vm.sortSuccess = function() {
    };

    vm.loadDropdownSuccess = function() {
        vm.loadData();
    };

    vm.onCuttingToolTypeChange = function (index) {

        var tool = vm.data.data[index];
        //console.log("Getting cutting tools for tool: "+tool.id+"; cutting type: "+tool.cutting_type_id);
        tool.cuttingToolList = [];
        $http({
            method: 'POST',
            url: vm.resource_url + "/cutting-tools",
            data: {id: tool.cutting_type_id}
        }).success(function (data) {
            //$log.debug(data);
            var tool = vm.data.data[index];
            var cuttingToolData = [];

            cuttingToolData.push({id: '',full_name: '[Select Cutting Tool]',name: '[Select Cutting Tool]'});

            angular.forEach(data.data, function (item,key) {
                var full_name = item.name;
                if (item.size) {
                    full_name = full_name +  '[' + item.size + ']';
                }
                if (item.style) {
                    full_name = full_name +  ' - ' + item.style;
                }
                item.full_name = full_name;

                cuttingToolData.push(item);
            });
            //$log.debug(cuttingToolData);
            tool.cuttingToolList = cuttingToolData;
            vm.onCuttingToolChange(index);

            setTimeout(function(){
                jQuery('select[name="cutting_tool_id"]').each(function(index){
                    $(this).trigger('chosen:updated');
                });
            }, 50);
            //$log.debug("Done loading cutting tools for for tool: "+tool.id+"; cutting type: "+tool.cutting_type_id);
        }).error(function (data) {
            $log.error(data);
        });

    };

    vm.onCuttingToolChange = function (index) {
        var tool = vm.data.data[index];
        tool.name = vm.cuttingToolName(tool);
    };

    vm.cuttingToolName = function (tool) {
        if (tool.cuttingToolList !== undefined) {
            for (i = 0; i < tool.cuttingToolList.length; i++) {
                if (tool.cutting_tool_id == tool.cuttingToolList[i].id) {
                    return tool.cuttingToolList[i].full_name;
                }
            }
        }
        return "[Select Cutting Tool]";
    };

    vm.first = function(obj) {
        for (var a in obj) return a;
    };


    vm.reloadDropdowns = function() {
        if (vm.dropdown_url) {
            $http({
                method: 'GET',
                url: vm.dropdown_url,
                params: {'op_id' : context_id}
            }).success(function (data) {
                vm.dropdowns = data.data;
            });
        }
    };

    vm.loadDropdowns(vm.resource_url + '/dropdowns', {'op_id': context_id});

}]);