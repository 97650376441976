// Please note that $modalInstance represents a modal window (instance) dependency.

prodproApp.controller('ModalInstanceCtrl', ['$scope', '$modalInstance','objText', function ($scope, $modalInstance,objText) {
	
	$scope.objText = objText;

    $scope.ok = function () {
        $modalInstance.close($scope.form);
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
}]);