var MeasurementsController = function(vm, base_url, $http, $location, $log, $modal){

    var measurements_list_url = api_prefix + 'insp/feat/list';
    vm.measurements_resource_url = api_prefix + 'insp/feat';
    vm.objText = {
        singular: 'measurement',
        plural: 'measurements'
    };


    vm.modelConfig = vm.modelConfig || {};

    vm.modelConfig.measurements = {

        list_url: api_prefix + 'insp/feat/{feat}/meas/list',
        resource_url: api_prefix + 'insp/feat/{feat}/meas',

        defaultObj: {
            interval: '',
            measurement: '',
            success: 0,
            editMode: true,
            addToTop: true,
            id: null
        },

        get_list_url : function(feature){

            return this.list_url.replace("{feat}", feature.id);
        },

        get_resource_url : function(feature_id){

            return this.resource_url.replace("{feat}", feature_id);
        }
    };

    vm.loadMeasurements = function (feature) {
        var url = vm.modelConfig.measurements.get_list_url(feature);
        $http({
            method: 'POST',
            url: url,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            transformRequest: function (obj) {
                var str = [];
                for (var p in obj)
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                return str.join("&");
            },
            data: {
                'feature_id' : feature.id,
                'context_mode': context_mode,
                'context_type': context_type,
                'context_id': context_id
            }
        }).success(function (data) {
            feature.measurements = data.data;
            console.log('Loaded Measurements for: ' + feature);
        }).error(function (data) {
            $log.error.log(data);
        });
    };

    vm.loadDataSuccess = function() {

        // load the measurements for the features
        angular.forEach(vm.data.data, function(feature){
            vm.loadMeasurements(feature);
        });

        setTimeout(function(){
            jQuery('textarea').autogrow();
        }, 10);
    };

    vm.addMeasurement = function(feature){

        var options = {
            model: feature,
            foreignKey: 'feature_id',
            addTo: 'measurements',
            edit: true,
            id: null
        };

        vm.addItem(options);

        console.log(feature);
    };

    vm.updateMeasurement = function(measurement, feature){

        var options = {
            resource_url: vm.modelConfig.measurements.get_resource_url(feature.id),
            model: feature,
            updateTo: 'measurements'
        };

        vm.updateItem(measurement, options)
    };

    vm.editMeasurement = function(measurement){

        vm.editItem(measurement);
    };

    vm.deleteMeasurement = function(measurement, feature){

        var options = {
            model: feature,
            deleteTo: 'measurements',
            resource_url: vm.modelConfig.measurements.get_resource_url(feature.id)
        };

        vm.deleteItem(measurement, options);
    };

    vm.cancelMeasurement = function(measurement, feature){

        var options = {
            model: feature,
            cancelTo: 'measurements'
        };

        vm.cancelItem(measurement, options);

    }
};