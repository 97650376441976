prodproApp.config(function(LightboxProvider) {
    LightboxProvider.fullScreenMode = true;
});

prodproApp.factory('favoriteService', ['$http', '$log', 'Lightbox', function ($http, $log, Lightbox) {
    var favoriteService = {};
    favoriteService.favoriteIndex = -1;
    favoriteService.images = new Array();

    favoriteService.updateFavorite = function(index) {
        favoriteService.favoriteIndex = index;
        return $http({
            method: 'PATCH',
            url: api_prefix + 'documents/' + favoriteService.images[index].id,
            data: {
                'favorite': 1
            }
        }).success(function (data) {
            if (data.type === 'success') {
                favoriteService.favoriteIndex = index;
            }
        }).error(function (data) {
            $log.error.log(data);
        });
    };

    favoriteService.getImage = function() {
        if(typeof favoriteService.images[favoriteService.favoriteIndex] !== 'undefined') {
            return favoriteService.images[favoriteService.favoriteIndex].thumbUrl;
        }
        return "";
    };

    favoriteService.setFavorite = function(index) {
        favoriteService.favoriteIndex = index;
    };

    favoriteService.getName = function() {
        if(typeof favoriteService.images[favoriteService.favoriteIndex] !== 'undefined') {
            return favoriteService.images[favoriteService.favoriteIndex].name;
        }
        return "";
    };

    favoriteService.setName = function(name) {
        favoriteService.images[favoriteService.favoriteIndex].name = name;
    };

    favoriteService.setIndex = function(index) {
        favoriteService.favoriteIndex = index;
    };

    favoriteService.getIndex = function() {
        return favoriteService.favoriteIndex;
    };

    favoriteService.setImages = function(images) {
        favoriteService.images = images;
    };

    favoriteService.getImages = function() {
        return favoriteService.images;
    };

    favoriteService.openLightboxModal = function (index) {
        var modalInstance = Lightbox.openModal(favoriteService.images, index);

        modalInstance.result.then(function (index) {
            $log.info('Image modal confirmed at: ' + new Date());
            $log.info('Image modal set new favorite to: ' + index);
            favoriteService.updateFavorite(index);
        }, function () {
            $log.info('Image modal dismissed at: ' + new Date());
        });
    };

    favoriteService.openFavoriteLightboxModal = function () {
        var modalInstance = Lightbox.openModal(favoriteService.images, favoriteService.favoriteIndex);

        modalInstance.result.then(function (index) {
            $log.info('Image modal confirmed at: ' + new Date());
            $log.info('Image modal set new favorite to: ' + index);
            favoriteService.updateFavorite(index);
        }, function () {
            $log.info('Image modal dismissed at: ' + new Date());
        });
    };

    return favoriteService;
}])

.directive('favoriteImage', [function() {
    return {
        bindToController: true,
        controller: "favoriteImageController as fav",
        templateUrl: "favoriteImage.html"
    };
}])

.controller("favoriteImageController", ['favoriteService', function(favoriteService) {
    var vm = this;
    vm.favoriteService = favoriteService;
}]);