var SortController = function (vm, $http, $modal, $timeout) {

    var beforeSortItems = [];

	vm.sortableOptions = {
		placeholder: 'ui-state-highlight',
        containment: 'parent',
        cursor: 'move',
        handle: '.dragger',
        tolerance: 'pointer',
        start: function (e,ui) {
            //Collapse everything or the sort won't work right
            angular.forEach(vm.data.data, function(value,key) {
                value.isExpanded = false;
            });

            ui.placeholder.height(ui.item.height());
            beforeSortItems = vm.data.data.slice(); //slice is required to unbind from scope
        },
		stop: function (e, ui) {

            ppnotify('confirm', {
                title: 'Confirm Sort',
                text: 'Are you sure you want to sort these ' + vm.objText.plural,
                type: 'info',
                closeOnConfirm: true,
                closeOnCancel: true,
                callback: function(isConfirm) {
                    if (isConfirm) {
                        //sort confirmed
                        var post = {
                            '_method': "PATCH",
                            'context_id': context_id,
                            'context_type': context_type,
                            'data': []
                        };

                        angular.forEach(vm.data.data,function(item,key) {
                            var post_item = {
                                'sort_id': item.id,
                                'order': key
                            };
                            post['data'].push(post_item);
                        });

                        $http({
                            method: 'POST',
                            url: vm.sort_url,
                            data: post
                        });

                        //redo order
                        angular.forEach(vm.data.data, function (value,key) {
                            value.order = key+1;
                        });

                        vm.sortSuccess();
                        /*ppnotify('alert', {
                            title: 'Success',
                            message: 'Successfully sorted ' + vm.objText.plural,
                            type: 'success'
                        });*/
                    }else{
                        $timeout(function() {
                            vm.data.data = beforeSortItems;
                        },0);
                    }
                }
            });
		}
	};
};