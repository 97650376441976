(function() {
	$('body').on('fireFlash',function() {
		$('.flash').fadeIn(300).delay(2500).fadeOut(300);
	});

})();

(function() {
	$('[data-toggle="tooltip"]').tooltip();

	if ($('.flash-modal').length ) {
		$('.flash-modal').modal();
	}

	//Advanced search panel - jQuery/CSS
	$("#adv-search-toggle").click(function(e) {
		e.preventDefault();

		$('#search-form').toggleClass('adv-open', function() {
			$(this).trigger('chosen:updated');
		});
	});

	//check if flash was fired
	if ($.trim($('.flash p').html()) != '') {
		$('body').trigger('fireFlash');
	}

    //add class based on index for main nav hover effect
    $("ul.navbar-action li").hover(function(index) {
	    var index = $(this).index();
		$('nav').attr('class', 'navbar hover at_'+ index);
	},
	function(index) {
		$('nav').attr('class', 'navbar');
	});

	//add active class to parent li and header
    $("ul.navbar-action li").each(function() {
		var url = window.location.href.split('?')[0]; //Dump the query string for matching purposes
		$('.nav a[href="'+url+'"]').parent().addClass('active');

		var activeindex = $('ul.navbar-action li.active').index();

		if(activeindex < 0) {
			$('header').addClass('no-active');
		} else {
			$('header').addClass('active_'+ activeindex);
		}
	});

})();

function resultSize(sel) {
	var uri = location.search;

	if (uri.length == 0) {
		location.search = "pp=" + sel.value;
	}else {
		location.search = updateQueryStringParameter(uri,"pp",sel.value);

	}
}

/*
 * This function replaces a query parameter if it exists
 * and adds it if it doesn't.
 *
 * http://stackoverflow.com/questions/5999118/add-or-update-query-string-parameter
 */
function updateQueryStringParameter(uri, key, value) {
	var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
	var separator = uri.indexOf('?') !== -1 ? "&" : "?";
	if (uri.match(re)) {
		return uri.replace(re, '$1' + key + "=" + value + '$2');
	}
	else {
	    return uri + separator + key + "=" + value;
	}
}

/***
 * File Ipload Controls
 */
$(document).ready( function() {
    $('input[type=file]').bootstrapFileInput();
    $('.file-inputs').bootstrapFileInput();
});


/**
 * global wrapper for sweet alert
 *
 * This is to help allow a more centralized handling of notifications.  Right now this function supports confirm boxes
 * and alerts.  There are defaults for each type, which are then merged from the options passed into the function
 *
 * @param type The type of notification (alert or confirm)
 * @param options A javascript object that is merged into the defaults for the notification type
 */
function ppnotify(type, options){

	switch(type){
		case "confirm":

			var defaults = {
				title: 'Are you sure?',
				text: 'Please confirm',
				type: 'warning',
				showCancelButton: true,
				confirmButtontext: 'Yes',
				cancelButtonText: 'Cancel',
				confirmButtonColor: '#286090',
				closeOnConfirm: false,
				closeOnCancel: false,
				'callback': function(isConfirm){
					if(isConfirm) {
						swal("Confirmed!", "You have been confirmed.", 'success');
					}else{
						swal('Cancelled!', 'You have been cancelled.', 'error');
					}
				}
			};

			options = jQuery.extend(defaults, options);
			swal(options , options.callback);

			break;
		case "alert":

			var defaults = {
				title: 'Alert',
				message: 'Alert',
				type: 'success',
				confirmButtonColor: '#286090',
				html: false,
			};

			options = jQuery.extend(defaults, options);

			//swal(options.title, options.message, options.type, );

			swal({
				title: options.title,
				text: options.message,
				type: options.type,
				html: options.html,
				confirmButtonColor: options.confirmButtonColor
			})

			break;
	}

}


/**
 * Allow tab panels to be toggleable
 */
$('.nav-tabs a').each(function(index, item) {
	$(item).click(function(e) {
		var tab = $(this);
		if(tab.parent('li').hasClass('active')) {
			$('.nav-tabs li.active').removeClass('active');
			$('.tab-pane.active').removeClass('active');
			e.stopPropagation(); // stop propagation as bootstrap will assume the tab is closed, and reopen it
		}
	})

});
