prodproApp.controller('HistoryListController', ['$rootScope', '$http', '$location', '$modal', '$log', '$timeout', 'alertService', function ($rootScope, $http, $location, $modal, $log, $timeout, alertService) {
    var vm = this;
    var base_url = api_prefix + 'qa/incidenthistory/list';
    vm.resource_url = api_prefix + 'qa/incidenthistory';
    vm.objText = {
        singular: 'Incident History',
        plural: 'Incident History'
    };

    vm.date_opened = [];

    // Mixin Module functionality into ActionsList.
    angular.extend(this, new ModuleController(vm, base_url, $http, $location, $log, $modal));

    vm.initDefaultObj = function() {
        vm.defaultObj.problem = "";
        vm.defaultObj.filled_out_on = new Date();

        //Add any new responses to the beginning of the list
        vm.addToTop = true;
    };

    vm.loadDropdownSuccess = function() {
        vm.loadData();
    };

    vm.loadDataSuccess = function() {

        if(vm.data.data.length != 0){
            $rootScope.incidentStatus = vm.getHistoryStatusView(vm.data.data[0].history_type);
        }else{
            $rootScope.incidentStatus = '';
        }


        setTimeout(function(){
            jQuery('textarea').autogrow();
        }, 10);
    };

    vm.saveItemSuccess = function() {
        vm.loadData();
    };

    vm.getHistoryStatusView = function(status){

        switch(status){
            case 'reporting':
                return 'Initial Reporting';
                break;
            case 'review':
                return 'Finding/Review';
            break;
            case 'action':
                return 'Corrective Action';
            break;
            case 'monitoring':
                return 'Monitoring';
            break;
        }
    };

    vm.createAction = function(type){

        vm.addItem();
        var item = vm.data.data[(vm.data.data.length - 1)];
        item.history_type = type;

    };

    vm.saveHistory = function(history) {

        this.updateItem(history);
    };

    vm.deleteHistory = function (action) {

        var notifyoptions = {
            title: 'Are you sure?',
            text: 'Are you sure you want to delete this incident history item?',
            type: 'warning',
            showCancelButton: true,
            confirmButtontext: 'Yes',
            cancelButtonText: 'Cancel',
            closeOnConfirm: true,
            closeOnCancel: true,
            'callback': function (isConfirm) {
                if (isConfirm) {
                    $http({
                        method: 'DELETE',
                        url: vm.resource_url + '/' + action.id
                    }).success(function (data) {
                        vm.loadData();
                        //swal(data.type, data.msg, 'success');
                    }).error(function (data) {
                        swal("Error", data.msg, 'error');
                        $log.error(data);
                    });
                }
            }
        };

        ppnotify('confirm', notifyoptions)
    };

    // Date picker functions
    vm.openUIDatePicker = function($event, index) {
        vm.date_opened[index] = true;
    };

    vm.uiDatePickerOptions = {
        formatYear: 'yyyy',
        startingDay: 1
    };


    vm.loadDropdowns(vm.resource_url + '/dropdowns');
}]);