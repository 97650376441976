var FileController = function (vm, $scope, $http, $modal, $timeout) {
    vm.file_resource_url = api_prefix + 'files';

    vm.dropElements = [];

    if (!vm.acceptedFiles) {
        vm.acceptedFiles = '';
    }

    $scope.dropzoneConfig = {
        'options': { // passed into the Dropzone constructor
            'url': vm.file_resource_url,
            autoProcessQueue: false,
            uploadMultiple: true,
            parallelUploads: 100,
            maxFiles: 100,
            acceptedFiles: vm.acceptedFiles,
            init: function () {
                var dropElm = this;

                this.on('addedfile', function (file) {
                    //console.log('Adding file:' + dropElm);
                    //console.log(dropElm);

                    vm.dropElements.push(dropElm);

                    // add button to remove file from dropzone element

                    // Create the remove button
                    var removeButton = Dropzone.createElement("<button>Remove file</button>");
                    // Capture the Dropzone instance as closure.
                    var _this = this;

                    // Listen to the click event
                    removeButton.addEventListener("click", function (e) {
                        // Make sure the button click doesn't submit the form:
                        e.preventDefault();
                        e.stopPropagation();

                        // Remove the file preview.
                        _this.removeFile(file);
                        // If you want to the delete the file on the server as well,
                        // you can do the AJAX request here.
                    });

                    // Add the button to the file preview element.
                    file.previewElement.appendChild(removeButton);

                });

                this.on('error', function(error, errorMessage) {
                    ppnotify('alert', {
                        title: 'File Upload Error',
                        message: errorMessage,
                        type: 'error'
                    });

                    this.removeFile(error);
                });
            }
        },
        'eventHandlers': {
            'sending': function (file, xhr, formData) {
            },
            'success': function (file, response) {
                vm.loadData();
            }
        }
    };

    vm.updateItem = function (item, options) {

        var options = options || {};
        var resource_url;
        resource_url = vm.resource_url;
        if (options.resource_url) {
            resource_url = options.resource_url;
        }

        ppnotify('confirm', {
            title: 'Confirm Save',
            text: 'Are you sure you want to save this ' + vm.objText.singular + '?',
            type: 'info',
            closeOnConfirm: true,
            closeOnCancel: true,
            callback: function (isConfirm) {
                if (isConfirm) {

                    if (item.id) {
                        //UPDATE ACCESSORY
                        $http({
                            method: 'PUT',
                            url: resource_url + '/' + item.id,
                            data: item
                        }).success(function (data) {
                            vm.pushAlert({type: data.type, msg: data.msg});
                            var successMsg = data.msg;

                            angular.forEach(vm.dropElements, function (dropElm, key) {

                                dropElm.on('sending', function (file, xhr, formData) {

                                    formData.append('fileable_type', vm.file_model);
                                    formData.append('fileable_id', item.id);
                                    formData.append('_token', jQuery('meta[name="_token"]').attr('content'));

                                });

                                dropElm.processQueue();
                            });

                            // next get deleted files and send request to files deletepath
                            var deleteFiles = jQuery('input[data-tool="' + item.id + '"][name="deleteFiles[]"]:checked').map(function () {
                                return $(this).val();
                            }).get().join();

                            $http({
                                method: 'POST',
                                url: vm.file_resource_url + '/delete',
                                data: {'deleteFiles': deleteFiles}
                            }).success(function (data, status) {

                                if (options.model) { // if the options has a model and updateTo field, update the sub model data instead of the view model data
                                    if (options.updateTo) {
                                        var modelData = options.model[options.updateTo];
                                    }
                                } else {
                                    var modelData = vm.data.data;
                                }

                                angular.forEach(modelData, function (value, key) {
                                    if (value.id == item.id) {
                                        value = data.data;
                                    }
                                });
                                item.editMode = false;

                                /*ppnotify('alert', {
                                    title: 'Success',
                                    message: successMsg,
                                    type: 'success'
                                });*/

                                vm.reloadDropdowns();
                                vm.loadData();

                            }).error(function (data, status) {
                                ppnotify('alert', {
                                    title: 'Error',
                                    message: 'Error saving',
                                    type: 'error'
                                });
                            });
                        }).error(function (data, status) {
                            if (status == 422) {
                                ppnotify('alert', {
                                    title: 'Error',
                                    message: 'Validation error',
                                    type: 'error'
                                });
                                vm.validationErrors(item, data);
                            } else {
                                ppnotify('alert', {
                                    title: 'Error',
                                    message: data.msg,
                                    type: 'error'
                                });
                            }
                        });
                    } else {
                        //NEW ACCESSORY
                        item.context_id = context_id;
                        item.context_type = context_type;
                        item.fileable_type = vm.file_model;

                        //console.log(item);
                        //console.log($scope);

                        $http({
                            method: 'POST',
                            url: resource_url,
                            data: item
                        }).success(function (data) {

                            vm.pushAlert({type: data.type, msg: data.msg});


                            //Post back data to last element
                            var items;
                            if (options.model) { // if we're doing a sub model, use those items instead
                                items = options.model[options.updateTo];
                            } else {
                                items = vm.data.data;
                            }
                            items[(items.length - 1)] = data.data;

                            item.editMode = false;

                            var fileableid = data.data.id;

                            angular.forEach(vm.dropElements, function (dropElm, key) {

                                dropElm.on('sending', function (file, xhr, formData) {

                                    formData.append('fileable_type', vm.file_model);
                                    formData.append('fileable_id', fileableid);
                                    formData.append('_token', jQuery('meta[name="_token"]').attr('content'));
                                });

                                dropElm.processQueue();
                            });

                            var addToTop = false;

                            if (options.addToTop) {
                                addToTop = true;
                            } else if (vm.addToTop) {
                                addToTop = true;
                            }

                            if (addToTop) {
                                vm.data.data.unshift(vm.data.data.pop());
                            }

                            vm.reloadDropdowns();
                            vm.loadData();

                            ppnotify('alert', {
                                title: 'Success',
                                message: data.msg,
                                type: 'success'
                            });
                        }).error(function (data, status) {
                            if (status == 422) {
                                ppnotify('alert', {
                                    title: 'Error',
                                    message: 'Validation error',
                                    type: 'error'
                                });
                                vm.validationErrors(item, data);
                            } else {
                                ppnotify('alert', {
                                    title: 'Error',
                                    message: data.msg,
                                    type: 'error'
                                });
                            }
                        });
                    }
                }
            }
        });

    };
};