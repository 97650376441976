prodproApp.controller('FeaturesController', ['$http', '$location', '$modal', '$log', '$timeout', 'alertService', function ($http, $location, $modal, $log, $timeout, alertService) {
    var vm = this;
    var base_url = api_prefix + 'insp/feat/list';
    vm.resource_url = api_prefix + 'insp/feat';
    vm.sort_url = api_prefix + 'insp/feat/sort';
    vm.objText = {
        singular: 'feature',
        plural: 'features'
    };

    // Mixin Module functionality into FeaturesList.
    angular.extend(this, new ModuleController(vm, base_url, $http, $location, $log, $modal));

    vm.initDefaultObj = function() {
        vm.defaultObj.text = "";
        vm.defaultObj.name = "";

        //Add any new responses to the beginning of the list
        vm.addToTop = true;
    };

    vm.loadDropdownSuccess = function() {
        vm.loadData();
    };

    vm.loadDataSuccess = function() {

        setTimeout(function(){
            jQuery('textarea').autogrow();
        }, 10);
    };

    vm.sortSuccess = function() {
    };

    angular.extend(this, new MeasurementsController(vm, base_url, $http, $location, $log, $modal));
    angular.extend(this, new SortController(vm, $http, $modal, $timeout));
    vm.loadDropdowns(vm.resource_url + '/dropdowns');
}]);