

var prodpro = prodpro || {};

prodpro.qa = prodpro.qa || {};
prodpro.qa.form = prodpro.qa.form || {};


prodpro.qa.form.init = function() {


    if(jQuery('select[name="part_id"]')){
          jQuery('select[name="part_id"]').on('change', function(){
              var part_id = jQuery('select[name="part_id"]').val();
              var url = api_prefix + 'qa/partruns/' + part_id;
              jQuery.get(url, function(data){

                  if(data.data.length === 0){
                      ppnotify('alert', {
                          'title': 'No Part Runs',
                          'message': 'There are no part runs for the part you selected',
                          'type': 'warning'
                      });
                  }

                  jQuery.each(data.data, function(key, value) {
                      jQuery('select[name="part_run_id"]').append(jQuery("<option/>", {
                          value: key,
                          text: value
                      }));
                  });
                  jQuery('select[name="part_run_id"]').trigger('chosen:updated');
              });

          });
    }

};

prodpro.qa.form.init();

