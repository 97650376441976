// Please note that $modalInstance represents a modal window (instance) dependency.

prodproApp.controller('HistoryModalInstanceCtrl', ['$scope', '$modalInstance', 'dropdowns', function ($scope, $modalInstance, dropdowns) {
    vm = this;

    vm.actionDropdowns = dropdowns;
    vm.history_type = null;

    vm.problem_filled_out_on_opened = false;
    vm.root_cause_filled_out_on_opened = false;
    vm.action_due_opened = false;
    vm.action_filled_out_on_opened = false;
    vm.results_filled_out_on_opened = false;

    $scope.ok = function () {
        $modalInstance.close(vm.history_type);
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };


    // Date picker functions
    vm.openUIDatePicker = function($event, datePicker) {
        vm[datePicker + '_opened'] = true;
    };

    vm.uiDatePickerOptions = {
        formatYear: 'yyyy',
        startingDay: 1
    };


}]);