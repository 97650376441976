(function() {

	/**
	 * Toggle the part favorites
	 */
	$('body').on('click','#part-fav',function(e){
		e.preventDefault;
		var fav_link = $(this);

		var post = {
				'_method' : 'PATCH',
				'_token' : $('meta[name="_token"]').attr('content'),
				'data'	: []
		};

		//Before we run ajax change the icon so it looks a little more instant
		var icon = fav_link.children('i:first');

    	if (icon.hasClass('fa-star-o')) {
			icon.removeClass('fa-star-o').addClass('fa-star');
    	}else {
			icon.removeClass('fa-star').addClass('fa-star-o');
    	}

		$.ajax({
			type: 'POST',
            url: api_prefix + 'parts/' + context_id + '/fav',
            data: post
        }).done(function(data){
        	if (data.data) {
				icon.removeClass('fa-star-o').addClass('fa-star');
        	}else {
				icon.removeClass('fa-star').addClass('fa-star-o');
        	}
        });
	});
})();