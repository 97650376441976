(function() {

	if($('#vendor_id').length) {
		//Initially
		loadVendor();

		//On change
		$("#vendor_id").change(function(){
			loadVendor();
		});
	}
})();

function loadVendor(){
	$('#vendor-info').html('').load(api_prefix + 'vendors/' + $('#vendor_id').val());
}
