prodproApp.controller('CommentController', ['$http', '$location', '$modal', '$log', function ($http, $location, $modal, $log) {
    var vm = this;
    var base_url = api_prefix + 'comments/list';
    vm.store_url = api_prefix + 'comments';
    vm.resource_url = api_prefix + 'comments';
    vm.reply = -1;
    vm.reply_text = "";
    vm.objText = {
        singular: 'comment',
        plural: 'comments'
    };

    vm.showReply = function (id) {
        if (vm.reply === id) {
            vm.reply = -1;
        } else {
            vm.reply = id;
        }
    };

    vm.isOpen = function (id) {
        return vm.reply == id;
    };

    // Mixin Module functionality into DocumentList.
    angular.extend(this, new ModuleController(vm, base_url, $http, $location));

    vm.initDefaultObj = function() {
    }

    vm.loadDataSuccess = function() {
        //vm.items = vm.data.items;
        var level = 0;
        angular.forEach(vm.data, function(item){vm.recursive(item, level)});
    };

    vm.recursive = function(item, level) {
        item.level = level;
        angular.forEach(item.items, function(innerItem){
            //innerItem.parent = item;
            vm.recursive(innerItem, level+1);
        });
    };

    vm.submitReply = function (reply_parent_id) {
        var url = vm.store_url;
        $http({
            method: 'POST',
            url: url,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            transformRequest: function (obj) {
                var str = [];
                for (var p in obj)
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                return str.join("&");
            },
            data: {
                'commentable_type': context_type,
                'commentable_id': context_id,
                'parent_id': reply_parent_id,
                'text': vm.reply_text
            }
        }).success(function (data) {
            //$log.info('reply added: ' + data.comment.id);
            vm.reply = -1;
            vm.reply_text = "";
            vm.loadData();
        }).error(function (data) {
            $log.error(data);
        });
    };

    vm.loadData();
}]);