prodproApp.filter('capitalizeFirst', function(){
    return function(input){
        if(typeof input == 'undefined'){
            return;
        }
        if(input.length == 0){
            return;
        }
        return input.charAt(0).toUpperCase() + input.slice(1)
    }
});