/**
 * Ajax form out of laravel route
 * @param uri
 */
(function ( $ ) {
	var defaults = {url: '', callback : function() {}};
	$.laravel_form = function(options) {
		var settings = $.extend({},defaults,options);

		//Reset form state
		var dialog = $("#form-dialog");
		dialog.find("#remote-page").html("").hide();
		dialog.find(".loading-container").show();

		dialog.modal();

		//Get form
		$.ajax({
			type: 'GET',
			url: settings.url
		}).done(function(data) {
			$("#form-dialog #remote-page").html(data).show();
			$("#form-dialog .loading-container").hide();
		});

		//Handle form submission
		$('#form-dialog').one('submit','form',function(e) {
			var form = $(this);
			var method = form.find('input[name="_method"]').val() || 'POST';
			var url = form.prop('action');

			var ajax_data = $.extend({},form.serializeObject(),options.data);

			//Lock down form from any further editing
			form.find('input,textarea,button,radio,checkbox').attr('disabled','disabled');
			form.find('input[type="submit"]').val("Saving...");
			form.find('button[type="submit"]').html("Saving...");

			$.ajax({
				type: method,
				url: url,
				data: $.param(ajax_data)
			}).done(function(data) {
				$('#form-dialog').modal("hide");
				settings.callback.call(this,data);
			}).fail(function(jqXHR) {
				if (jqXHR.status == 422) {
					$.each(jqXHR.responseJSON, function(k,v) {
						//Loop through the errors and display them
						var field = form.find('[data-name="' + k + '"]');
						field.addClass('has-error');
						field.append('<span class="help-block">' + v + '</span>');
					});
				}
			});

			e.preventDefault();
		});

		$('#form-dialog').on('click','[data-ajax="cancel"]',function(e) {
			e.preventDefault();

			$("#form-dialog").modal("hide");
		});
	};

	/**
	 * http://stackoverflow.com/questions/1184624/convert-form-data-to-js-object-with-jquery
	 */
	$.fn.serializeObject = function()
	{
	    var o = {};
	    var a = this.serializeArray();
	    $.each(a, function() {
	        if (o[this.name] !== undefined) {
	            if (!o[this.name].push) {
	                o[this.name] = [o[this.name]];
	            }
	            o[this.name].push(this.value || '');
	        } else {
	            o[this.name] = this.value || '';
	        }
	    });
	    return o;
	};
}( jQuery ));

/***
	Form ajax helper functions (https://laracasts.com/lessons/javascript-conveniences)
***/
(function() {

	$('body').on('submit','form[data-remote]',function(e) {
		var form = $(this);
		var method = form.find('input[name="_method"]').val() || 'POST';
		var url = form.prop('action');

		$.ajax({
			type: method,
			url: url,
			data: form.serialize()
		}).done(function() {
			var message = form.data('remote-success-message');

			if (message) {
				$('.flash h4').html(message);
				$('body').trigger('fireFlash');
			}

		}).fail(function(jqXHR) {
			if (jqXHR.status == 422) {
				$.each(jqXHR.responseJSON, function(k,v) {
					//Loop through the errors and display them
					var field = form.find('[data-name="' + k + '"]');
					field.addClass('has-error');
					field.append('<span class="help-block">' + v + '</span>');
				});
			}
		});

		e.preventDefault();
	});

	/*$('input[data-confirm],button[data-confirm]').on('click',function(e) {
		var input = $(this);

		input.prop('disabled','disabled');

		if (!confirm(input.data('confirm'))) {
			e.preventDefault();
		}

		input.removeAttr('disabled');
	});*/


	//Load these helpers whenever a new page is loaded or we say it explicitly
	$('body').on('pp:jqueryHelp',function(){

		//Add jquery Chosen
		$("select").each(function() {

	        //skip no-chosen select
	        if (typeof $(this).attr('chosen') === 'undefined' &&
				typeof $(this).attr('no-chosen') === 'undefined'
			) {
	        //if (!$(this).is('[no-chosen]')) {
	            var config = {
	                width: "95%",
	                inherit_select_classes: true,
	                allow_single_deselect: true,
	                disable_search_threshold: true,
					scroll_to_highlighted: false
	            };


	            if ($(this).hasClass('required')) {
	                config.allow_single_deselect = false;
	            }

	            if ($(this).hasClass('no-search')) {
	                config.disable_search_threshold = 20;
	            }

				if($(this).attr('data-add-option') || $(this).attr('data-add-option-route')){
					var url;
					if($(this).attr('data-add-option')){
						url = api_prefix + 'dd';
					}else if($(this).attr('data-add-option-route')){
						url = $(this).attr('data-add-option-route');
					}
					var fieldName = 'name';
					if($(this).attr('data-add-option-field-name')) {
						fieldName = $(this).attr('data-add-option-field-name');
					}

					config.create_option = function(term){
						var chosen = this;

						var confirmOptions = {
							title: 'Are you sure?',
							text: 'Are you sure you want to to add this option?',
							type: 'info',
							showCancelButton: true,
							confirmButtontext: 'Yes',
							cancelButtonText: 'Cancel',
							closeOnConfirm: true,
							closeOnCancel: true,
							'callback': function(isConfirm){
								if(isConfirm) {
									var dd_model = chosen.form_field_jq.data('add-option');
									var dd_parent = 0;
									if (chosen.form_field_jq.data('related-to')) {
										var id = chosen.form_field_jq.data('related-to');
										dd_parent = $('#' + id).val();
										console.log(dd_parent);
									}

									var postData = {
										_token: $('meta[name="_token"]').attr('content'),
										model: dd_model,
										parent_id: dd_parent
									};
									postData[fieldName] = term;

									$.post(url, postData, function(data){
										chosen.append_option({
											value: data.id,
											text: data.name
										});
										/*ppnotify('alert', {
											title: 'Added',
											message: '"' + data.name + '" option added',
											type: 'success'
										});*/
									});
								}
							}
						};
						ppnotify('confirm', confirmOptions);
					}
					config.persistent_create_option = true;
				}

	            $(this).chosen(config);
	        }
		});


		//Add asterisk to required field labels
		$('input.required,textarea.required,select.required').each(function() {
			var label = $("label[for='"+$(this).attr('id')+"']");

			if (label.find('.fa-asterisk').length == 0) {
				var html = label.html();
				html += '<i class="fa fa-asterisk"></i>';
				label.html(html);
			}
		});

		//Spinner
		$('input.spinner').not('.no-jquery').spinner();
		$('input.spinner-natural').not('.no-jquery').spinner({
			min: 0
		});

		//Datepicker
		$('input.datepicker').not('.no-jquery').datepicker();

	});

	$('body').trigger('pp:jqueryHelp');

})();



// name spacing the javascript allows for global execution and definition of javascript vars and functions, but still
// keeps it from interfering on other javascript on the page
var pp = pp || {};
pp.form = pp.form || {}; // define the prodpro.form namespace

// define the delete namespace and initialize the delete functionality provided by the gist from jeffrey way
pp.form.delete = pp.form.delete || {};
pp.form.delete.link = null;
pp.form.delete.laravel = {

	/*
	 * Sending a delete request from outside a form (Thanks, Jeffery Way!)
	 *
	 * https://gist.github.com/JeffreyWay/5112282
	 *
	 <a href="posts/2" data-method="delete"> <---- We want to send an HTTP DELETE request
	 - Or, request confirmation in the process -
	 <a href="posts/2" data-method="delete" data-confirm="Are you sure?">
	 */

	initialize: function() {
		this.methodLinks = $('a[data-method]');

		this.registerEvents();
	},

	registerEvents: function() {
		this.methodLinks.on('click', this.handleMethod);
	},

	handleMethod: function(e) {
		pp.form.delete.link = $(this);
		var httpMethod = pp.form.delete.link.data('method').toUpperCase();
		e.preventDefault();

		// If the data-method attribute is not PUT or DELETE,
		// then we don't know what to do. Just ignore.
		if ( $.inArray(httpMethod, ['PUT', 'DELETE']) === - 1 ) {
			return;
		}

		// Allow user to optionally provide data-confirm="Are you sure?"
		if ( pp.form.delete.link.data('confirm') ) {

			console.log(pp.form.delete.link.data('confirm'));
			ppnotify('confirm', {
					title: 'Confirm Delete',
					text: pp.form.delete.link.data('confirm'),
					type: 'warning',
					closeOnConfirm: false,
					closeOnCancel: true,
					callback: function (isConfirm) {
						if (isConfirm) {
							pp.form.delete.form = pp.form.delete.laravel.createForm(pp.form.delete.link);
							pp.form.delete.form.submit();
						}
					}
				}
			);
		}
	},

	createForm: function(link) {
		var form =
			$('<form>', {
				'method': 'POST',
				'action': link.attr('href')
			});

		var token =
			$('<input>', {
				'type': 'hidden',
				'name': '_token',
				'value': $('meta[name="_token"]').attr('content')
			});

		var hiddenInput =
			$('<input>', {
				'name': '_method',
				'type': 'hidden',
				'value': link.data('method')
			});

		return form.append(token, hiddenInput)
			.appendTo('body');
	}
};

// initialize the delete confirms
pp.form.delete.laravel.initialize();

// define the save confirm namespace
pp.form.save = pp.form.save || {};
pp.form.save = {
	initialize: function(){

		$(document).ready(function() {
			$('form').keydown(function(event){
				if(event.keyCode == 13) {
					event.preventDefault();
					return false;
				}
			});
		});

		$('button[type="submit"][data-confirm], input[type="submit"][data-confirm]').each(function(index){
			$(this).on('click', function(e){
				e.preventDefault();
				var button = $(this);

				var title = 'Confirm Save';
				if(button.data('confirm-type')){
					if(button.data('confirm-type') == 'duplicate'){
						title = 'Confirm Duplication';
					}
				}

				ppnotify('confirm', {
					title: title,
					text: button.data('confirm'),
					type: 'info',
					closeOnConfirm: false,
					closeOnCancel: true,
					callback: function(isConfirm){
						if(isConfirm){
							button.off('click');
							button.click();
						}
					}
				});
			});
		});
	}
};
// init the save dialogues
pp.form.save.initialize();

// define the time input form namespace
pp.form.time = pp.form.time || {};

pp.form.time = {
	initialize : function () {
		$('input[placeholder="HH:MM:SS"]').each(function (index) {
			$(this).mask('00:00:00');
		});

		// For individual time inputs i.e. an input for hours, an input for minutes, and an input for seconds, we want to mask for correct values
		$('input[placeholder="HH"]').each(function(index) {


			$(this).on('blur', function(e) {
				var val = pp.form.time.format_digits($(this).val());
				$(this).val(val);
			});
		});

		$('input[placeholder="MM"], input[placeholder="SS"]').each(function(index){
			$(this).on('blur', function(e) {
				var val = pp.form.time.format_digits($(this).val());
				$(this).val(val);
			});
		});
	},
	format_digits: function(n){
		if (n == "") {
			return "";
		}
		n = parseInt(n);
		if (n == 0) {
			return "00";
		}
		return n > 9 ? "" + n: "0" + n;
	}
};

pp.form.time.initialize();


pp.form.autogrow = pp.form.autogrow || {};

pp.form.autogrow = {
	initialize: function() {
		jQuery('document').ready(function(){
			jQuery('textarea').autogrow();
		});
	}
};

pp.form.autogrow.initialize();


