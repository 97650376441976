prodproApp.controller('RevisionListController', ['$rootScope', '$http', '$location', '$modal', '$log', 'flagService', 'alertService', function ($rootScope, $http, $location, $modal, $log, flagService, alertService) {
    var vm = this;
    var base_url = api_prefix + 'revisions/list';
    vm.resource_url = api_prefix + 'revisions';
    vm.objText = {
        singular: 'revision',
        plural: 'revisions'
    };

    vm.processRevisions = [];
    vm.partRevisions = [];

    vm.process = {};
    vm.revision = {};
    vm.process.alerts = [];
    vm.revision.alerts = [];


    // Mixin Module functionality into DocumentList.
    angular.extend(this, new ModuleController(vm, base_url, $http, $location));

    vm.initDefaultObj = function () {
    };

    vm.loadRevisions = function (type) {

        switch (type) {
            case 'process':
                vm.process.alerts = [];
                vm.loadData({'revision_type': type});

                break;
            case 'revision':
                vm.revision.alerts = [];
                vm.loadData({'revision_type': type});

                break;
        }
    };

    vm.loadDataSuccess = function () {

        // not a perfect way, but gets the job done instead of introducing yet another api change in the modules.js file
        vm.processRevisions = [];
        vm.partRevisions = [];
        angular.forEach(vm.data.data, function (revision) {

            if (revision.type == 'process') {
                vm.processRevisions.push(revision);
            } else if (revision.type == 'revision') {
                vm.partRevisions.push(revision);
            }
        });


        // very hacky way to get the most recent revision's name to the part details page, however, writing a service
        // to just pass a variable up to the part details pane without really being a full angular application, and adding another
        // controller on top of it is kind of overkill as well.
        if (vm.partRevisions.length != 0) {
            $rootScope.mostRecentRevision = vm.partRevisions[0].name;
        }

        //Check for open revisions
        vm.data.revAdd = ' disabled';
        flagService.setValid(true);
        if (vm.data.part.open_rev) {
            flagService.setValid(false);
        }
        if (vm.data.part.incomp_rev) {
            flagService.setValid(false);
        }

        if (vm.data.part.open_process_rev) {
            vm.process.alerts.push({type: 'warning', msg: 'This part has been edited since the last revision.'});
            vm.data.revAdd = '';
        }

    };

    vm.createRevision = function (type) {

        var type = type || 'revision';

        var url = '';
        if (context_id) {
            url = '/parts/' + context_id + '/revisions/create/' + type;
        }

        window.location.href = url;

    };

    vm.editRevision = function (id) {

        var url = '';
        if (context_id) {
            url = '/parts/' + context_id + '/revisions/' + id + '/edit';
        }

        window.location.href = url;

    };

    vm.completeRevision = function (id, type) {

        ppnotify('confirm', {
            title: 'Confirm Acknowledgement',
            text: 'Are you sure you want to acknowledge this revision?',
            type: 'warning',
            closeOnConfirm: true,
            closeOnCancel: true,
            callback: function (isConfirm) {
                if (isConfirm) {
                    $http({
                        method: 'PATCH',
                        url: api_prefix + 'revisions',
                        /*contentType: 'application/json; charset=UTF-8',*/
                        data: {
                            'completed': 1,
                            'context_id': context_id,
                            'id': id
                        }
                    }).success(function (data) {
                        flagService.setValid(true);
                        alertService.closeAlertIdx(0);
                        vm.loadRevisions(type);
                        /*ppnotify('alert', {
                            title: 'Success',
                            message: 'Acknowledged revision',
                            type: 'success'
                        });*/
                    }).error(function (data) {
                        $log.error(data);
                        ppnotify('alert', {
                            title: 'Error',
                            message: 'Error acknowledging this revision',
                            type: 'error'
                        });
                    });
                }
            }
        });
    };

    /**
     * OVERRIDE
     *
     * This is overridden due to needing to custom load revisions after a successful delete of a part revision
     *
     * @param item
     * @param options
     */
    vm.deleteItem = function (item, options) {

        var options = options || {};

        var resource_url = vm.resource_url;
        if (options.resource_url) {
            resource_url = options.resource_url;
        }

        var itemText = 'item';
        if (vm.objText.singular) {

        }

        ppnotify('confirm', {
            title: 'Confirm Delete',
            text: 'Are you sure you want to delete this ' + vm.objText.singular,
            type: 'warning',
            closeOnConfirm: true,
            closeOnCancel: true,
            callback: function (isConfirm) {
                if (isConfirm) {
                    $http({
                        method: 'DELETE',
                        url: resource_url + "/" + item.id
                    }).success(function (data) {
                        vm.pushAlert({type: data.type, msg: data.msg});

                        var alertMsg = data.msg;

                        vm.loadRevisions('revision');

                        /*ppnotify('alert', {
                            title: 'Success',
                            message: alertMsg,
                            type: 'success'
                        });*/
                    }).error(function (data) {
                        $log.error(data);
                        ppnotify('alert', {
                            title: 'Error',
                            message: data.msg,
                            type: 'error'
                        });
                    });

                }
            }
        });
    };


}]);
