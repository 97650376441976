prodproApp.factory('flagService', [function () {
    var flagService = {};
    var valid = null;

    flagService.getValid= function () {
    	if (valid === null) return '';
    	if (valid) {
    		return 'green-icon fa-flag';
    	}
        return 'red-icon fa-warning';
    };

    flagService.setValid = function (v) {
    	valid = v;
    };

    return flagService;
}])

    .directive('flag', [function () {
        return {
            bindToController: true,
            controller: "flagController as f",
            templateUrl: "partFlag.html"
        };
    }])

    .controller("flagController", ['flagService', function (flagService) {
        var vm = this;
        vm.flagService = flagService;
    }]);