prodproApp.controller('NoteController', ['$http', '$location', '$modal', '$log', '$timeout', '$filter', '$sce', 'alertService', function ($http, $location, $modal, $log, $timeout, alertService) {
    var vm = this;
    var base_url = api_prefix + 'notes/list';
    vm.resource_url = api_prefix + 'notes';
    vm.objText = {
        singular: 'note',
        plural: 'notes'
    };

    // Mixin Module functionality into DocumentList.
    angular.extend(this, new ModuleController(vm, base_url, $http, $location, $log, $modal));

    vm.initDefaultObj = function() {
        vm.defaultObj.text = "";
        vm.defaultObj.name = "";
    };

    vm.loadDataSuccess = function() {
    };

    vm.loadData();
}]);