prodproApp.controller('SpindleController', ['$scope','$http', '$location', '$modal', '$log', '$timeout', 'alertService', function ($scope, $http, $location, $modal, $log, $timeout, alertService) {
    var vm = this;
    var base_url = api_prefix + 'spindles/list';
    vm.sort_url = api_prefix + 'spindles/sort';
    vm.resource_url = api_prefix + 'spindles';
    vm.objText = {
        singular: 'spindle',
        plural: 'spindles'
    };
    vm.file_model = 'App\\Repos\\Spindles\\Spindle';

    // Mixin Module functionality.
    angular.extend(this, new ModuleController(vm, base_url, $http, $location, $log, $modal));
    angular.extend(this, new SortController(vm, $http, $modal, $timeout));

    vm.acceptedFiles = 'image/jpg,image/jpeg,image/png,image/gif';
    angular.extend(this, new FileController(vm, $scope, $http, $modal, $timeout));

    //Set up the default object
    vm.initDefaultObj = function () {
        vm.defaultObj.spindle_type_id = '';
        vm.defaultObj.jaw_type_id = '';
        vm.defaultObj.lathe_type_id = '';
        vm.defaultObj.size = '';
        vm.defaultObj.jaw_no = '';
        vm.defaultObj.notes = '';
    };

    vm.loadDataSuccess = function () {
    };

    vm.loadDropdownSuccess = function () {
        vm.loadData();
    };

    vm.sortSuccess = function() {
    };

    vm.first = function (obj) {
        for (var a in obj) return a;
    };

    vm.loadDropdowns(vm.resource_url + '/dropdowns');

}]);