var ModuleController = function (vm, base_url, $http, $location, $log, $modal) {
        vm.base_url = base_url;
        vm.data = [];
        vm.current_page = $location.search().page ? $location.search.page : 1;
        vm.last_page = vm.page;
        vm.pages = [];
        vm.alerts = [];
        vm.dropdowns = [];
        vm.dropdown_url = null;
        vm.origItem = {};
        vm.addToTop = false;
        vm.map = {};
        vm.map["App\\Repos\\Parts\\Part"] = 'parts';
        vm.dataLoading = true;
        vm.defaultObj = {
            name: '[Default]',
            isExpanded: true,
            editMode: true,
            id: null
        };

        /**
         *
         * @param params An optional object to extend with the request
         */
        vm.loadData = function (params) {

            var controllerParams = params || {};

            var parameters = {
                'context_mode': context_mode,
                'context_type': context_type,
                'context_id': context_id
            };

            angular.extend(parameters, controllerParams);

        	var page = angular.isUndefined(vm.current_page) ? '' : '?page=' + vm.current_page;
            var url = vm.base_url + page;
            $http({
                method: 'POST',
                url: url,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function (obj) {
                    var str = [];
                    for (var p in obj)
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    return str.join("&");
                },
                data: parameters
            }).success(function (data) {
                vm.data = data;
                vm.current_page = data.current_page;
                vm.last_page = data.last_page;
                vm.pages = vm.getPages(data.last_page);
                vm.dataLoading = false;
                vm.loadDataSuccess();
                vm.initDefaultObj();
            }).error(function (data) {
                $log.error.log(data);
            });
        };

        vm.loadDropdowns = function (url, params) {

            var parameters = params || {};

            vm.dropdown_url = url;
            $http({
                method: 'GET',
                url: vm.dropdown_url,
                params: parameters
            }).success(function (data) {
                vm.dropdowns = data.data;
                vm.loadDropdownSuccess();
            }).error(function (data) {
                $log.error.log(data);
            });
        };

        vm.create = function () {
            var url = vm.create_url;

            if (context_type && context_id) {
                if (vm.map[context_type]) {
                    url += '/' + vm.map[context_type] + '/' + context_id;
                }
            }

            window.location.href = url;

        };

        /////////////////////////////////////////////////////////////////////////////////
        ///Lists
        /////////////////////////////////////////////////////////////////////////////////
        vm.addItem = function (options) {

            if(options){ // if there are options we need to add onto the model directly passed, or a sub model specified

                if(options.addTo){ // if we're adding to a specific element instead of directly onto the model

                    if(typeof options.model[options.addTo] == 'undefined') {
                        options.model[options.addTo] = [];
                        var last_item = undefined;
                    }else {
                        var last_item = options.model[(options.model[options.addTo].length -1)];
                    }

                    if (last_item != undefined && last_item.id === null) {
                        last_item.isExpanded = true;
                    } else {

                        var newObj = angular.copy(vm.modelConfig[options.addTo].defaultObj);

                        // if there is a foreign key object add the model's id to the sub model
                        if(options.foreignKey){
                            newObj[options.foreignKey] = options.model.id;
                        }

                        options.model[options.addTo].push(newObj);
                    }
                }

            }else { // this is here to not break compatibility with existing angular code
                var items = vm.data.data;

                angular.forEach(items, function (val, key) {
                    val.isExpanded = false;
                });

                var last_item = vm.data.data[(vm.data.data.length - 1)];

                if (last_item != undefined && last_item.id === null) {
                    last_item.isExpanded = true;
                } else {
                    items.push(angular.copy(vm.defaultObj));
                }
            }
        };

        vm.editItem = function (item) {
            item.isExpanded = true;
            item.editMode = !item.editMode;

            angular.copy(item,vm.origItem);
        };

        vm.cancelItem = function(item, options) {

            var data;
            if(options){
                data = options.model[options.cancelTo];
            }else{
                data = vm.data.data
            }

            //Remove any new items that haven't been saved
            for (var i = data.length -1; i >= 0; i--) {
                if (data[i].id === null) {
                    data.splice(i,1);
                }else if(data[i].id == vm.origItem.id) {
                    data[i] = vm.origItem;
                    data[i].editMode = false;
                    vm.origItem = {};
                }
            }

            item.editMode=false;
        };

        vm.validationErrors = function(item, data){
            item.errors = data;
        };

        vm.updateItem = function(item, options) {

            var options = options || {};
            var resource_url;
            resource_url = vm.resource_url;
            if(options.resource_url){
                resource_url = options.resource_url;
            }

            ppnotify('confirm', {
                title: 'Confirm Save',
                text: 'Are you sure you want to save this ' + vm.objText.singular + '?',
                type: 'info',
                closeOnConfirm: true,
                closeOnCancel: true,
                callback: function(isConfirm){
                    if(isConfirm){


                        if (item.id) {

                            //UPDATE
                            item.context_id = context_id;
                            item.context_type = context_type;
                            $http({
                                method: 'PUT',
                                url: resource_url + '/' + item.id,
                                data: item
                            }).success(function (data) {
                                vm.pushAlert({type: data.type, msg: data.msg});

                                if(options.model){ // if the options has a model and updateTo field, update the sub model data instead of the view model data
                                    if(options.updateTo){
                                        var modelData = options.model[options.updateTo];
                                    }
                                } else{
                                    var modelData = vm.data.data;
                                }

                                angular.forEach(modelData, function (value,key) {
                                    if (value.id == item.id) {
                                        value = data.data;
                                    }
                                });
                                item.editMode = false;

                                /*ppnotify('alert', {
                                    title: 'Success',
                                    message: data.msg,
                                    type: 'success'
                                });*/

                                if(typeof vm.saveItemSuccess == 'function'){
                                    vm.saveItemSuccess();
                                }

                            }).error(function (data,status) {
                                if (status == 422) {
                                    ppnotify('alert', {
                                        title: 'Error',
                                        message: 'Validation error',
                                        type: 'error'
                                    });
                                    vm.validationErrors(item,data);
                                }else{
                                    ppnotify('alert', {
                                        title: 'Error',
                                        message: data.msg,
                                        type: 'error'
                                    });
                                }
                            });
                        } else {
                            //NEW ITEM
                            item.context_id = context_id;
                            item.context_type = context_type;
                            $http({
                                method: 'POST',
                                url: resource_url,
                                data: item
                            }).success(function (data) {
                                vm.pushAlert({type: data.type, msg: data.msg});

                                //Post back data to last element
                                var items;
                                if(options.model){ // if we're doing a sub model, use those items instead
                                    items = options.model[options.updateTo];
                                }else{
                                    items = vm.data.data;
                                }
                                items[(items.length-1)] = data.data;

                                item.editMode = false;

                                var addToTop = false;

                                if(options.addToTop){
                                    addToTop = true;
                                } else if(vm.addToTop){
                                    addToTop = true;
                                }

                                if (addToTop) {
                                    vm.data.data.unshift(vm.data.data.pop());
                                }

                                /*ppnotify('alert', {
                                    title: 'Success',
                                    message: data.msg,
                                    type: 'success'
                                });*/

                                if(typeof vm.saveItemSuccess == 'function'){
                                    vm.saveItemSuccess();
                                }

                            }).error(function (data, status) {
                                if (status == 422) {
                                    ppnotify('alert', {
                                        title: 'Error',
                                        message: 'Validation error',
                                        type: 'error'
                                    });
                                    vm.validationErrors(item,data);
                                }else{
                                    ppnotify('alert', {
                                        title: 'Error',
                                        message: data.msg,
                                        type: 'error'
                                    });
                                }

                            });
                        }

                        vm.reloadDropdowns();


                    }
                }
            });



        };

        vm.deleteItem = function (item, options) {

            var options = options || {};

            var resource_url = vm.resource_url;
            if(options.resource_url){
                resource_url = options.resource_url;
            }

            var itemText = 'item';
            if(vm.objText.singular){

            }

            ppnotify('confirm', {
                title: 'Confirm Delete',
                text: 'Are you sure you want to delete this ' + vm.objText.singular,
                type: 'warning',
                closeOnConfirm: true,
                closeOnCancel: true,
                callback: function (isConfirm) {
                    if (isConfirm) {
                        $http({
                            method: 'DELETE',
                            url: resource_url + "/" + item.id
                        }).success(function (data) {
                            vm.pushAlert({type: data.type, msg: data.msg});

                            var alertMsg = data.msg;

                            //Delete from data
                            var data;
                            if(options.model){
                                data = options.model[options.deleteTo];
                            }else{
                                data = vm.data.data;
                            }

                            for (var i=0;i<data.length;i++) {
                                if (data[i].id === item.id) {
                                    data.splice(i, 1);
                                    break;
                                }
                            };

                            //Reindex order
                            angular.forEach(data, function(value,key) {
                                value.order = key+1;
                            });

                            if(options.model){
                                options.model[options.deleteTo] = data
                            }else {
                                vm.data.data = data;
                            }

                            /*ppnotify('alert', {
                                title: 'Success',
                                message: alertMsg,
                                type: 'success'
                            });*/
                        }).error(function (data) {
                            $log.error(data);
                            ppnotify('alert', {
                                title: 'Error',
                                message: data.msg,
                                type: 'error'
                            });
                        });

                    }
                }
            });
        };

        vm.reloadDropdowns = function() {
            if (vm.dropdown_url) {
                $http({
                    method: 'GET',
                    url: vm.dropdown_url
                }).success(function (data) {
                    vm.dropdowns = data.data;
                });
            }
        };

        /////////////////////////////////////////////////////////////////////////////////
        /// Pagination
        /////////////////////////////////////////////////////////////////////////////////
        vm.isFirst = function () {
            return vm.current_page === 1;
        };

        vm.isLast = function () {
            return vm.current_page === vm.last_page;
        };

        vm.isCurrent = function (page) {
            return vm.current_page === page;
        };

        vm.getPage = function (page) {
            vm.current_page = page;
            vm.loadData();
        };

        vm.nextPage = function () {
            if (!vm.isLast()) {
                vm.current_page++;
                vm.loadData();
            }
        };

        vm.prevPage = function () {
            if (!vm.isFirst()) {
                vm.current_page--;
                vm.loadData();
            }
        };

        vm.getPages = function (num) {
            var pages = [];
            for (var i = 1; i <= num; i++) {
                pages.push(i);
            }
            return pages;
        };

        vm.pushAlert = function(alert){

            vm.alerts = [];
            vm.alerts.push(alert);

        };

        vm.addAlert = function (type, msg) {
            vm.alerts.push({type: type, msg: msg});
        };

        vm.closeAlert = function (index) {
            vm.alerts.splice(index, 1);
        };

        vm.findDataIndex = function(key, value) {
            for(var i = 0; i < vm.data.data.length; i++) {
                if(vm.data.data[i][key] === value) {
                    return i;
                }
            }
            return 0;
        };

        vm.updateByKey = function (key,data) {
        	var idx = vm.findDataIndex(key,data.data[key]);


        	/**
        	 * TODO PP: NEED TO HANDLE ADDING A NEW ENTRY
        	 */
        	if (idx > 0) {
	        	$.each(data.data, function (i,v){
	        		vm.data.data[idx][i] = v;
	        	});
        	}

        	vm.loadData();
        }

    };



