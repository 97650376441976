prodproApp.controller('AccessoryController', ['$scope', '$http', '$location', '$modal', '$log', '$timeout','alertService', function ($scope, $http, $location, $modal, $log, $timeout, alertService) {
    var vm = this;
    var base_url = api_prefix + 'accessories/list';
    vm.sort_url = api_prefix + 'accessories/sort';
    vm.resource_url = api_prefix + 'accessories';
    vm.objText = {
        singular: 'accessory',
        plural: 'accessories'
    };
    vm.file_model = 'App\\Repos\\Accessories\\Accessory';

    // Mixin Module functionality.
    angular.extend(this, new ModuleController(vm, base_url, $http, $location, $log, $modal));
    angular.extend(this, new SortController(vm, $http, $modal, $timeout));

    vm.acceptedFiles = 'image/jpg,image/jpeg,image/png,image/gif';
    angular.extend(this, new FileController(vm, $scope, $http, $modal, $timeout));

    //Set up default object
    vm.initDefaultObj = function() {
        vm.defaultObj.device_id = "";
        vm.defaultObj.notes = "";
    };

    vm.loadDataSuccess = function() {
        $log.info("Accessory Data Loaded");
    };

    vm.loadDropdownSuccess = function() {
        $log.info("Accessory Dropdowns Loaded");
        vm.loadData();
    };

    vm.sortSuccess = function() {
    };

    vm.first = function(obj) {
        for (var a in obj) return a;
    };

    vm.loadDropdowns(vm.resource_url + '/dropdowns');

}]);