prodproApp.controller('SetupstepController', ['$http', '$location', '$modal', '$log', '$timeout', 'alertService', function ($http, $location, $modal, $log, $timeout, alertService) {
    var vm = this;
    var base_url = api_prefix + 'setupsteps/list';
    vm.sort_url = api_prefix + 'setupsteps/sort';
    vm.resource_url = api_prefix + 'setupsteps';
    vm.objText = {
        singular: 'setup step',
        plural: 'setup steps'
    };

    // Mixin Module functionality.
    angular.extend(this, new ModuleController(vm, base_url, $http, $location, $log, $modal));
    angular.extend(this, new SortController(vm, $http, $modal, $timeout));

    //Set up default object
    vm.initDefaultObj = function() {
        vm.defaultObj.notes = "";
    };

    vm.loadDataSuccess = function() {
    };

    vm.sortSuccess = function() {
    };

    vm.loadData();

}]);